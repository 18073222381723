@import '../../scss/variables.scss';
.popup {
	width: 100%;
	height: 100%;
	display: flex;
	position: fixed;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	z-index: 350;
	background-color: rgba(0, 8, 20, 0.35);
	transition:
		opacity 0.7s ease,
		visibility 0.2s ease;
}

.popup__container {
	width: 529px;
	border-radius: 16px;
	background: $main-white;
	padding: 36px 52px;
	display: flex;
	flex-direction: column;
	position: relative;
	box-sizing: border-box;
	box-shadow: 0 0 1px rgba(0, 0, 0, 1);
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	-ms-border-radius: 16px;
	-o-border-radius: 16px;
}

.popup__close-button {
	border: none;
	outline: none;
	background: url(../../images/close-popup-button.svg) no-repeat;
	position: absolute;
	right: 20px;
	top: 20px;
	width: 20px;
	height: 20px;
	background-size: 100%;
	transition: opacity 0.3s ease-in;
	cursor: pointer;
	padding: 0;
}

.popup__close-button:hover {
	opacity: 0.6;
}

.popup__title {
	margin: 0;
	color: $main-black;
	text-align: center;
	font-family: $font-family-roboto-flex;
	font-size: 28px;
	font-weight: 900;
	line-height: 32px;
	letter-spacing: 0.01em;
}

@media screen and (max-width: $breakpoint-sm) {
	.popup__container {
		width: 480px;
		padding: 16px;
		margin-top: 123px;
		display: flex;
		align-items: center;
	}
}

@media screen and (max-width: $breakpoint-xs) {
	.popup__container {
		width: 335px;
		margin-top: 10px;
	}
}

.map {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	margin-bottom: 70px;
}

.map__container {
	width: 1040px;
	height: 500px;
	padding-top: 32px;
}

.map__baloon {
	background-color: black;
	width: 150px;
	height: 150px;
	background-image: url(../../images/location.png);
}

.map__inputs {
	display: flex;
	justify-content: center;
	width: 1040px;
	gap: 8px;
	background: #fcfcfc;
	padding: 0 36px 24px 36px;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
}

.map__button {
	padding: 0;
	border: 0;
	width: 104px;
	height: 52px;
	background-image: url(../../images/buttonMap.png);
	border-radius: 8px;
	background-size: cover;
}

.map__button:hover {
	cursor: pointer;
}

.map__button_active {
	background-image: url(../../images/buttonCards.png);
}

.map__search-bar {
	border: none;
	width: 203.5px;
	height: 52px;
	border: 1px solid #c9d1e5;
	background: #fcfcfc;
	border-radius: 8px;
	padding-left: 10px;
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 20px;
}

.map__search-bar::placeholder {
	color: #898787;
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 20px;
}

.map__search-bar_type_search {
	width: 45%;
}
.map__search-bar_type_search-area {
	width: 100%;
}

.map__filter-button {
	display: none;
}

@media screen and (max-width: 1060px) {
	.map__container {
		width: 100%;
	}
	.map__inputs {
		width: 100%;
		padding: 0 44px 24px 44px;
	}
	.map__search-bar_area {
		width: 170px;
	}
	.map__search-bar_kinds-of-sports {
		width: 170px;
	}
	.map__search-bar_type_search {
		width: 270px;
	}
}

@media screen and (max-width: 866px) {
	.map__inputs {
		padding: 0 18px 16px 18px;
		display: grid;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 2fr;
		grid-template-areas:
			'button search'
			'area kinds';
	}
	.map__button {
		grid-area: button;
	}
	.map__search-bar_area {
		grid-area: area;
		width: 100%;
	}
	.map__search-bar_kinds-of-sports {
		grid-area: kinds;
		width: 100%;
	}
	.map__search-bar_type_search {
		grid-area: search;
		width: 100%;
	}
}

@media screen and (max-width: 495px) {
	.map__inputs {
		display: flex;
		flex-direction: row;
	}
	.map__button {
		display: none;
	}
	.map__button-mobile {
		display: block;
		width: 93px;
		height: 32px;
		border-radius: 40px;
		position: fixed;
		bottom: 5%;
		left: 37.6%;
		background: rgba(81, 81, 81, 0.95);
		z-index: 1;
		background-image: url(../../images/ButtonList.svg);
		background-position: center;
	}

	.map__button-mobile_active {
		background-image: url(../../images/ButtonMap.svg);
	}

	.map__search-bar_kinds-of-sports {
		display: none;
	}
	.map__search-bar_area {
		display: none;
	}
	.map__search-bar_type_search {
		height: 36px;
	}
	.map__filter-button {
		border: none;
		padding: 0;
		display: block;
		background-image: url(../../images/Button-filter.svg);
		width: 40px;
		height: 36px;
		background-size: cover;
		background-position: center;
		border-radius: 6px;
	}
	.map__filter-button {
		cursor: pointer;
	}
}

.ymaps-2-1-79-balloon__layout {
	border-radius: 8px;
	width: 358px;
	height: auto;
}

.ymaps-2-1-79-balloon {
	border-radius: 8px;
}

.yandex-link {
	text-decoration: none;
	color: black;
}

.yandex {
	display: flex;
	width: 318px;
}

.yandex__contetn {
	margin-left: 10px;
	width: 218px;
}

.yandex__categories {
	display: grid;
	width: 200px;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	justify-content: end;
	row-gap: 6px;
}

.yandex__category {
	min-height: 100%;
	margin-right: 10px;
	height: 20px;
	background: rgba(229, 240, 255, 1);
	display: flex;
	align-items: center;
	border-radius: 6px;
	justify-content: start;
	color: #153e8c;
}

.yandex__small-img {
	width: 12px;
	height: 12px;
	margin-left: 8px;
}

.yandex__small-text {
	font-family: Roboto;
	font-size: 10px;
	font-weight: 400;
	line-height: 12px;
	letter-spacing: 0em;
	text-align: left;
	margin: 0;
	margin-left: 8px;
	margin-right: 8px;
}

.yandex__images {
	width: 88px;
	height: 88px;
	border-radius: 8px;
}

.yandex__title {
	font-family: Roboto Flex;
	font-size: 14px;
	font-weight: 900;
	line-height: 16px;
	letter-spacing: 0.01em;
	text-align: left;
}

.yandex__subtitle {
	font-family: Roboto;
	font-size: 10px;
	font-weight: 400;
	line-height: 12px;
	letter-spacing: 0em;
	text-align: left;
	margin-bottom: 5px;
}

// Карта добавления карточки

.map_area-app {
	width: 100%;
	height: 80%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	.map__container {
		width: 724px;
		height: 340px;
	}

	.map__baloon {
		width: 150px;
		height: 150px;
		background-image: url(../../images/location.png);
	}

	.map__button_none {
		display: none;
	}

	.map__inputs_aprea {
		display: flex;
		width: 100%;
		padding: 0;
	}

	.map__search-bar {
		width: 724px;
		height: 42px;
		border-radius: 8px;
		margin-bottom: 10px;
		border: 1px solid rgba(201, 209, 229, 1);
	}

	.map__search-bar_kinds-of-sports {
		display: none;
	}

	.map__search-bar_area {
		display: none;
	}

	.ymaps-2-1-79-inner-panes {
		border-radius: 12px;
	}

	.ymaps-2-1-79-controls__control_toolbar {
		display: none;
	}
}

@media screen and (max-width: 878px) {
	.map_area-app {
		.map__container {
			width: 100%;
			padding: 0;
		}

		.map__baloon {
			width: 150px;
			height: 150px;
			background-image: url(../../images/location.png);
		}

		.map__inputs_aprea {
			display: flex;
			width: 100%;
			padding: 0;
		}

		.map__search-bar {
			width: 724px;
			height: 42px;
			border-radius: 8px;
			border: 1px solid rgba(201, 209, 229, 1);
		}

		.map__search-bar_kinds-of-sports {
			display: none;
		}

		.map__search-bar_area {
			display: none;
		}

		.ymaps-2-1-79-inner-panes {
			border-radius: 12px;
		}

		.ymaps-2-1-79-controls__control_toolbar {
			display: none;
		}
	}
}

@media screen and (max-width: 495px) {
	.map_area-app {
		.map__search-bar_type_search::placeholder {
			font-family: Roboto;
			font-size: 12px;
			font-weight: 400;
			line-height: 14px;
			letter-spacing: 0em;
			text-align: left;
		}
		.map__search-bar_type_search {
			width: 100%;
		}
	}
}

.password-recovery-form__container {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 425px;
}

.password-recovery-form {
	margin: 0;
	margin-top: 16px;
	display: flex;
	flex-direction: column;
}

.password-recovery-form__error {
	min-height: 22px;
	max-height: 32px;
	text-align: start;
	color: #f92e2e;
	font-size: 13px;
	font-weight: 400;
	line-height: 14px;
	padding: 4px 0;
}
.password-recovery-form__mail_activate {
	min-height: 18px;
	max-height: 32px;
	font-size: 13px;
	font-weight: 400;
	line-height: 14px;
	margin: 4px 0 8px;
	border: none;
	color: var(--Primary-Black-light, #515151);
	text-decoration-line: underline;
}

.password-recovery-form__button-send {
	margin: 0;
	margin-top: 10px;
	border-radius: 8px;
	background: var(--Accent-Blue, #1478ff);
	padding: 0;
	width: 100%;
	// width: 326px;
	height: 44px;
	color: var(--Primary-White, #fcfcfc);
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	text-align: center;
	align-self: baseline;
}

.password-recovery-form__text {
	margin: 0;
	padding: 16px 0px;
	color: var(--Primary-Gray-blue-dark, #515151);
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	display: flex;
	gap: 4px;
	align-self: center;
}

// 897px
@media screen and (max-width: 648px) {
	.password-recovery-form__container {
		width: 303px;
		align-items: baseline;
	}
	.password-recovery-form {
		width: 303px;
		margin-top: 8px;
	}
	.password-recovery-form__text {
		margin: 0;
		padding: 0 0 16px;
		font-size: 14px;
		line-height: 16px;
	}
}

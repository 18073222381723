@import '../../../scss/variables.scss';
// Mixins
@mixin border-radius($radius) {
	border-radius: $radius;
}

.password-data {
	display: flex;
	padding: 24px;
	flex-direction: column;
	background-color: $main-white;
	@include border-radius(12px);
}

.form__password-container {
	margin: 8px 0 0;
	display: flex;
	flex-direction: column;
}

.profile-password-label {
	font-family: $font-family-roboto;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	text-align: left;
	color: $main-black-light;
	margin: 0;
}

.profile-password-label:first-of-type {
	margin: 0;
}

.profile-password-input-container {
	display: flex;
	width: 425px;
	flex-direction: column;
	position: relative;
}

.password-show-hide-btn-profile {
	background: url(../../../images/show-hide-password-button-profile.svg)
		no-repeat center;
	width: 16px;
	height: 16px;
	display: flex;
	position: absolute;
	top: 13px;
	right: 13px;
	outline: none;
	border: none;
}

.profile__pass-button-container {
	display: flex;
	margin-top: calc(42px - 26px);
	gap: 8px;
}

.form__change-password-button {
	font-family: $font-family-roboto;
	width: 100%;
	background: url('../../../images/profile-page-arrow-right-icon.svg') no-repeat
		100% 50%;
	height: 36px;
	display: flex;
	gap: 8px;
	padding: 0;
	align-items: center;
	color: $main-gray-blue-dark;
	background-color: transparent;
	border: none;
	outline: none;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	@include border-radius(8px);
	cursor: pointer;
}

.no-margin {
	margin: 0;
}

@media screen and (max-width: $breakpoint-sm) {
	.form__change-password-button {
		height: 40px;
		padding: 10px 32px;
	}
}

// Variables
$title-text-color: #0e0e0e;
$text-color: #515151;
$font-family-title: 'Roboto Flex';
$font-family-text: 'Roboto';
$button-bg-color: #1478ff;
$button-text-color: #fcfcfc;

.reset-password-form__container {
	display: flex;
	// position: relative;
	align-self: center;
	height: fit-content;
	margin-top: 50px;
	width: 480px;
	flex-direction: column;
	background-color: #fcfcfc;
	//padding: 36px 48px;
	border-radius: 12px;
}

.reset-password-form,
.reset-password-form__success-container {
	display: flex;
	flex-direction: column;
	margin: 36px 48px;
}

.reset-password-form__title {
	font-family: $font-family-title;
	color: $title-text-color;
	font-size: 27px;
	font-weight: 900;
	line-height: 32px;
	letter-spacing: 0.01em;
	text-align: left;
	padding: 0;
	margin: 0;
}

.reset-password-form__text {
	padding: 0;
	margin: 8px 0 16px;
	font-family: $font-family-text;
	color: $text-color;
}

.reset-password-form__button {
	font-family: $font-family-text;
	width: 178px;
	height: 44px;
	color: $button-text-color;
	background-color: $button-bg-color;
	border: none;
	outline: none;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	text-align: center;
	padding: 8px 16px;
	margin-top: 10px;
	border-radius: 8px;
	cursor: pointer;
}

.reset-password-form__server-error {
	min-height: 22px;
	max-height: 32px;
	text-align: left;
	color: #f92e2e;
	font-size: 13px;
	font-weight: 400;
	line-height: 14px;
	padding: 4px 0;
}

.reset-password-form__server-error {
	text-align: start;
	max-height: 32px;
	color: #f92e2e;
	font-size: 13px;
	font-weight: 400;
	line-height: 14px;
	margin-top: 5px;
}

@media screen and (max-width: 648px) {
	.reset-password-form__container {
		width: 303px;
		align-items: baseline;
	}
	.reset-password-form {
		margin: 36px 16px 16px;
	}
	.reset-password-form__title {
		font-size: 24px;
		font-weight: 900;
		line-height: 26px;
		letter-spacing: 0.01em;
	}
	.reset-password-form__text {
		margin: 8px 0 0;
		padding: 0 0 16px;
		font-size: 14px;
		line-height: 16px;
	}
}

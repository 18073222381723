@import '../../scss/variables.scss';

.password-reset-form {
	margin: 0;
	margin-top: 12px;
	display: flex;
	flex-direction: column;
	align-self: baseline;
}

.password-reset-form__text {
	margin: 0;
	padding: 0px;
	color: $main-gray-blue-dark;
	font-family: $font-family-roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	display: flex;
	gap: 4px;
	align-self: center;
}

.password-reset-form__error {
	min-height: 22px;
	max-height: 32px;
	text-align: center;
	color: $modal-btn-red;
	font-size: 13px;
	font-weight: 400;
	line-height: 14px;
	padding: 6px 0;
}

.password-reset-form__button {
	margin: 0;
	border-radius: 8px;
	background: $accent-blue;
	padding: 12px 48px;
	width: fit-content;
	height: 44px;
	color: $main-white;
	font-family: $font-family-roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	text-align: center;
}

.popup__title-left {
	text-align: start;
	align-self: start;
}

@media screen and (max-width: $breakpoint-sm) {
	.popup__title.popup__title-left {
		text-align: left;
		align-self: baseline;
		font-size: 24px;
		line-height: 26px;
	}
}

@import '../../scss/mixins.scss';
@import '../../scss/variables.scss';

.header {
	@include flex-center;
	background-color: $main-white;
	height: 60px;

	&__container {
		display: flex;
		justify-content: space-between;
		width: 1040px;

		@include small-laptop {
			padding: 0 40px;
		}

		@include tablet {
			padding: 0 30px;
		}

		@include mobile {
			padding: 0 20px;
		}
	}

	&__buttons {
		display: flex;
		align-items: center;
		gap: 16px;

		@include tablet {
			display: none;
		}
	}
}

.button-menu {
	display: none;

	@include tablet {
		display: flex;
		@include flex-center;
		background: inherit;
		padding: 6px;
		height: 28px;
		width: 28px;
		border: none;

		svg path {
			fill: $accent-blue; // задает цвет svg
		}
	}
}

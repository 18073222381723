.view__container {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
}
.view__text {
	margin: 0;
	padding-top: 12px;
	padding-bottom: 28px;
	color: var(--Primary-Black-light, #515151);
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	display: flex;
	align-items: start;
	text-align: start;
}

.view__button-navigate-home {
	margin: 0;
	border-radius: 8px;
	background: var(--Accent-Blue, #1478ff);
	padding: 0;
	width: 184px;
	height: 44px;
	color: var(--Primary-White, #fcfcfc);
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	text-align: center;
}

.popup__title-left {
	text-align: start;
	align-self: start;
}

.popup__text {
	margin: 0;
	padding-top: 12px;
	padding-bottom: 28px;
	color: var(--Primary-Black-light, #515151);
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	display: flex;
	align-items: start;
	text-align: start;
}

.popup__text_type_areas {
	text-align: center;
}

.register-form__button-register {
	margin: 0;
	border-radius: 8px;
	background: var(--Accent-Blue, #1478ff);
	padding: 0;
	width: 100%;
	height: 44px;
	color: var(--Primary-White, #fcfcfc);
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	text-align: center;
	align-self: center;
}

.popup__title-left {
	text-align: start;
	align-self: start;
}

@import './variables.scss';

//  -------------- BASIC -----------------
@mixin mp-null {
	margin: 0;
	padding: 0;
}

// ------------ MEDIA QUERIES ------------

@mixin mobile {
	@media screen and (max-width: $breakpoint-xs) {
		@content;
	}
}

@mixin tablet {
	@media screen and (max-width: $breakpoint-sm) {
		@content;
	}
}

@mixin small-laptop {
	@media screen and (max-width: $breakpoint-md) {
		@content;
	}
}

// ---------------- LIST ----------------
@mixin mp-null-list {
	@include mp-null;
	list-style-type: none;
}

// ---------------- FLEX ----------------
@mixin flex-column {
	display: flex;
	flex-direction: column;
}

@mixin flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin flex-column-center {
	@include flex-column;
	align-items: center;
	justify-content: center;
}

//  ---------------- FONTS ----------------
@mixin text {
	@include mp-null;
	font-style: normal;
}

@mixin text-roboto-flex-900 {
	@include text;
	font-weight: 900;
	font-family: $font-family-roboto-flex;
}

@mixin text-roboto-700 {
	@include text;
	font-weight: 700;
	font-family: $font-family-roboto;
}

@mixin text-roboto-flex-600 {
	@include text;
	font-weight: 600;
	font-family: $font-family-roboto-flex;
}

@mixin text-roboto-600 {
	@include text;
	font-weight: 600;
	font-family: $font-family-roboto;
}

@mixin text-roboto-500 {
	@include text;
	font-weight: 500;
	font-family: $font-family-roboto;
}

@mixin text-roboto-400 {
	@include text;
	font-weight: 400;
	font-family: $font-family-roboto;
}

@mixin title-h1 {
	//Заголовок баннера на главной странице
	@include text-roboto-flex-900;
	font-size: 28px;
	line-height: 36px;
	font-variation-settings: $font-variation-settings-title;
}

@mixin title-h2 {
	// Заголовок модальных окон/ Главный заголовок на страницах
	@include text-roboto-flex-900;
	font-size: 27px;
	line-height: 32px;
	letter-spacing: 0.27px;
	font-variation-settings: $font-variation-settings-title;
}

@mixin title-h3 {
	// Заголовок разделов в ЛК
	@include text-roboto-flex-900;
	font-size: 26px;
	line-height: 28px;
	letter-spacing: 0.26px;
	font-variation-settings: $font-variation-settings-title;
}

@mixin title-h4 {
	// Заголовок подразделов на страницах площадок и создания
	@include text-roboto-flex-900;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.2px;
	font-variation-settings: $font-variation-settings-title;
}

@mixin title-h4-logo {
	// Текст в логотипе
	@include text-roboto-flex-600;
	color: $main-black-light;
	font-size: 20px;
	line-height: 28px;
	text-transform: uppercase;
	font-variation-settings: $font-variation-settings-logo;

	@include tablet {
		font-size: 16px;
		line-height: 20px;
	}
}

@mixin subtitle {
	// Используется в баннере на главной странице
	@include text-roboto-400;
	font-size: 18px;
	line-height: 22px;
}

@mixin button-text {
	// Используется в тексте кнопок
	@include text-roboto-700;
	font-size: 16px;
	line-height: 20px;
}

@mixin button-text-small-size {
	// Используется в тексте кнопок
	@include text-roboto-700;
	font-size: 14px;
	line-height: 20px;
}

@mixin chat-username-text {
	// Используется в имени пользователей в чатах
	@include text-roboto-600;
	font-size: 16px;
	line-height: 20px;
}

@mixin main-text {
	// Используется во вспомогательных сообщениях в модальных окнах / Меню раздела ЛК
	@include text-roboto-400;
	font-size: 16px;
	line-height: 20px;
}

@mixin input-text {
	// Используется в качестве текста внутри инпутов / как текст в выпадающих списках
	@include text-roboto-400;
	font-size: 15px;
	line-height: 20px;
}

@mixin mini-card-text {
	// Используется в мини-карточках площадки на карте
	@include text-roboto-flex-900;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.14px;
}

@mixin big-tag-text {
	// Используется в больших тэгах вида спорта
	@include text-roboto-500;
	font-size: 14px;
	line-height: 16px;
}

@mixin small-tag-text {
	// Используется в больших тэгах вида спорта
	@include text-roboto-400;
	font-size: 10px;
	line-height: 12px;
}

@mixin secondary-text {
	// Используется в инпутах в Label / основной и вспомогательный размер текса внутри чатов / в тэгах видов спорта внутри инпута на странице создания площадки
	@include text-roboto-400;
	font-size: 14px;
	line-height: 20px;
}

@mixin input-help-text {
	// Используется в инпутах в Help Text
	@include text-roboto-400;
	font-size: 13px;
	line-height: 14px;
}

.password__container {
	position: relative;
}

.password__show-hide-btn {
	background: url(../../images/show-hide-password-button-log-reg.svg) no-repeat
		center;
	width: 16px;
	height: 16px;
	position: absolute;
	top: 14px;
	right: 14px;
	outline: none;
	border: none;
	cursor: pointer;
}

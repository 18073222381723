$fontsRoboto: 'Roboto';

@mixin displayPosition {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	/* gap: 4px; */
}

@mixin blocks {
	width: 1040px;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	background: #fcfcfc;
	padding: 24px 36px;
	/* 	margin-bottom: 24px; */
}

.location__container {
	@include blocks;
	margin: 40px auto 0px;
}

.location__title {
	font-family: Roboto Flex;
	font-size: 28px;
	font-weight: 900;
	line-height: 36px;
	letter-spacing: 0em;
	text-align: center;
	margin: 0;
	font-variation-settings:
		'wdth' 128,
		'GRAD' 42,
		'slnt' 0,
		'XTRA' 486,
		'XOPQ' 96,
		'YOPQ' 79,
		'YTLC' 544,
		'YTUC' 712,
		'YTAS' 750,
		'YTDE' -203,
		'YTFI' 738;
}

.location__subtitle {
	margin: 0;
	padding-top: 4px;
	color: var(--Primary-Black-light, #515151);
	font-size: 18px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: center;
}

@media screen and (max-width: 1060px) {
	.location__container {
		width: 100%;
		padding: 24px 36px;
	}
}

@media screen and (max-width: 866px) {
	.location__container {
		width: 669px;
	}
}

@media screen and (max-width: 710px) {
	.location__container {
		width: 100%;
	}
}

@media screen and (max-width: 495px) {
	.location__container {
		width: 100%;

		padding: 16px 12px;
	}

	.location__title {
		font-size: 18px;
		font-weight: 959;
		line-height: 22px;
		letter-spacing: 0.02em;
	}
	.location__subtitle {
		display: none;
	}
}

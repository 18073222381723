.popup__login-form-link-recover {
	color: var(--Primary-Gray-blue-dark, #737a8e);
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	text-decoration-line: none;
}

.popup__login-form-link-recover:hover {
	text-decoration-line: underline;
}

@media screen and (max-width: 648px) {
	.popup__login-form-link-recover {
		font-size: 14px;
	}
}

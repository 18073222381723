@import '../../../scss/variables.scss';
// Mixins
@mixin border-radius($radius) {
	border-radius: $radius;
}

// Styles
.favourites {
	display: flex;
	flex-direction: column;
	padding: 24px;
	background-color: $main-white;
	@include border-radius(12px);
}

.favourites__title {
	font-family: $font-family-roboto-flex;
	color: $secondary-black;
	text-align: left;
	font-size: 20px;
	font-weight: 600;
	line-height: 22px;
	letter-spacing: 0.01em;
	margin: 0;
}

.favourites__navigation {
	display: flex;
	width: 100%;
	justify-content: space-between;
	gap: 4px;
}

.favourites__card-list {
	width: 100%;
	display: grid;
	justify-items: center;
	margin: 0;
	list-style-type: none;
	grid-template-columns: repeat(3, minmax(204px, max-content));
	grid-template-rows: repeat(auto-fit, minmax(230px, max-content));
	gap: 12px;
	justify-content: center;
	padding: 24px 0 0 0;
}

.favourites__card-list {
	.card {
		width: 204px;
		height: 230px;
		box-shadow: 0px 4px 8px 0px #737a8e33;

		&__image {
			width: 100%;
			height: 142px;
		}
		&__container {
			width: 100%;
			padding: 8px;
		}
		&__title {
			font-family: $font-family-roboto-flex;
			font-size: 13px;
			font-weight: 900;
			line-height: 16px;
			letter-spacing: 0.01em;
			text-align: left;
		}
		&__subtitle {
			font-family: $font-family-roboto;
			font-size: 10px;
			font-weight: 400;
			line-height: 12px;
			text-align: left;
			height: 24px;
			display: -webkit-box;
			-webkit-line-clamp: 2; /* ограничиваем кол-во строк до 2 */
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
			margin: 0;
		}
		&__link {
			position: relative;
			box-shadow: 8px 8px 8px rgba(115, 122, 142, 0.2);
		}
		&__categories-container {
			display: flex;
			position: absolute;
			bottom: 15px;
			left: 0;
			margin: 0 0 0 8px;
		}
		&__categories {
			background-color: $accent-light-blue;
		}
	}
	.sports-ground__button {
		margin: 0;
		position: absolute;
		right: 8px;
		top: 8px;
		z-index: 15;
		background-color: rgba(252, 252, 252, 0.8);
		width: 20px;
		height: 20px;
		border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
	}
	.sports-ground__button-text {
		display: none;
	}
	.sports-ground__button-like {
		background-image: url('../../../images/button-like-inactive-profile.svg');
	}
	.sports-ground__button-like_active {
		background-image: url('../../../images/button-like-active-profile.svg');
	}
}

.favourites__list {
	width: 100%;
	display: grid;
	margin: 0;
	list-style-type: none;
	grid-template-rows: repeat(auto-fit, minmax(72px, max-content));
	padding: 0;
}

.favourites__list {
	.card {
		display: flex;
		width: 100%;
		height: 96px;
		padding: 12px;
		&__image {
			width: 72px;
			height: 72px;
			@include border-radius(4px);
		}
		&__container {
			width: 100%;
			height: 72px;
			padding: 0 0 0 8px;
		}
		&__title {
			font-family: $font-family-roboto-flex;
			font-size: 13px;
			font-weight: 900;
			line-height: 16px;
			letter-spacing: 0.01em;
			text-align: left;
		}
		&__subtitle {
			font-family: $font-family-roboto;
			font-size: 10px;
			font-weight: 400;
			line-height: 12px;
			text-align: left;
			height: 24px;
			width: 234px;
			display: -webkit-box;
			-webkit-line-clamp: 2; /* ограничиваем кол-во строк до 2 */
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
			margin: 0;
		}
		&__link {
			position: relative;
			display: flex;
			width: 100%;
		}
		&__categories-container {
			display: flex;
			position: absolute;
			bottom: 0;
			left: 0;
			margin: 0 0 0 72px;
		}
		&__categories {
			background-color: $accent-light-blue;
		}
	}
	.sports-ground__button {
		margin: 0;
		position: absolute;
		right: 8px;
		top: 28px;
		z-index: 15;
		background-color: transparent;
		width: 20px;
		height: 20px;
	}
	.sports-ground__button-text {
		display: none;
	}
	.sports-ground__button-like {
		background-image: url('../../../images/button-like-inactive-profile.svg');
	}
	.sports-ground__button-like_active {
		background-image: url('../../../images/button-like-active-profile.svg');
	}
}

.favourites__buttons {
	display: flex;
	width: calc(28px + 28px + 2px);
	justify-content: space-between;
	height: 28px;
	gap: 2px;
}

.favourites__button {
	outline: none;
	border: none;
	background-color: transparent;
}

.favourites__button_type_card {
	background: url('../../../images/card-view-icon-profile-page.svg') no-repeat
		center;
	width: 28px;
}

.favourites__button_type_card_active {
	background: url('../../../images/card-view-icon-profile-page_active.svg')
		no-repeat center;
	width: 28px;
}

.favourites__button_type_list {
	background: url('../../../images/list-view-icon-profile-page.svg') no-repeat
		center;
	width: 28px;
}

.favourites__button_type_list_active {
	background: url('../../../images/list-view-icon-profile-page_active.svg')
		no-repeat center;
	width: 28px;
}

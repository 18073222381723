.message__text {
	margin: 0;
	color: var(--Primary-Black-light, #515151);
	font-family: Roboto Flex;
	font-size: 27px;
	font-weight: 900;
	line-height: 32px;
	letter-spacing: 0.01em;
	text-align: left;
	display: flex;
	align-self: baseline;
	padding: 0;
	width: 384px;
}

.message__text-error {
	color: #f92e2e;
}

.view-form__button-signin {
	margin: 28px 0 0;
	border-radius: 8px;
	background: var(--Accent-Blue, #1478ff);
	padding: 12px 48px;
	width: fit-content;
	height: 44px;
	color: var(--Primary-White, #fcfcfc);
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	text-align: center;
	align-self: baseline;
	cursor: pointer;
}

@media screen and (max-width: 898px) {
	.message__text {
		width: 303px;
		font-size: 27px;
		line-height: 32px;
	}
}

@import '../../scss/variables.scss';

.logout-form__container {
	display: flex;
	max-width: 312px;
	justify-content: left;
	padding: 0;
}

.popup__title-left {
	text-align: start;
	align-self: start;
}

@media screen and (max-width: $breakpoint-sm) {
	.popup__title-left {
		text-align: left;
		align-self: baseline;
	}
}

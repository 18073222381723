@import '../../../scss/variables.scss';

// Mixins
@mixin border-radius($radius) {
	border-radius: $radius;
}

// Styles
.personal-data {
	display: flex;
	flex-direction: column;
	background-color: $main-white;
	padding: 24px;
	@include border-radius(12px);
}

.form_place_profile {
	color: #767676;
	width: 480px;
	display: flex;
	flex-direction: column;
}

.profile__personal-info-container {
	display: flex;
	align-items: center;
	margin-bottom: 24px;
	column-gap: 8px;
}

.profile__avatar-button {
	// background: url(../../../images/user-icon-avatar.jpg) center no-repeat;
	margin: 0;
	padding: 0;
	border: 0;
	height: 52px;
	width: 52px;
	@include border-radius(50%);
	background-color: transparent;
	cursor: pointer;
}

.profile__avatar {
	height: 100%;
	width: 100%;
	margin: 0;
}

.profile__name-container {
	display: flex;
	flex-direction: column;
	row-gap: 2px;
}

.profile__name {
	font-family: $font-family-roboto;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	text-align: left;
	margin: 0;
	color: $secondary-black;
}

.profile__email {
	font-size: 13px;
	font-weight: 400;
	line-height: 18px;
	text-align: left;
	color: $main-black-light;
	margin: 0;
	padding: 0;
}

.profile__change-info-container {
	width: 425px;
	display: flex;
	flex-direction: column;
}

.profile__input {
	width: 100%;
	height: 44px;
	display: flex;
	border: 1px solid $main-gray-blue-light;
	outline: none;
	background-color: transparent;
	text-align: left;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	padding: 0;
	margin: 15px 0 0;
	align-items: center;
	color: $secondary-black;
	@include border-radius(8px);
}

.profile__input:first-child {
	margin-top: 0;
}

.error {
	font-family: $font-family-roboto;
	display: block;
	min-height: 26px;
	margin: 4px 0 0;
	padding: 0;
}

.profile__info-container {
	display: flex;
	flex-direction: column;
}

.profile__field-title {
	font-family: $font-family-roboto;
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	text-align: left;
	color: $main-black-light;
	margin: 0 0 4px;
	padding: 0;
}

.profile__field-name {
	font-size: 15px;
	font-weight: 400;
	line-height: 20px;
	text-align: left;
	color: $secondary-black;
	padding: 0;
	margin: 0 0 30px;
}

.profile__field-name:last-child {
	margin-bottom: 0;
}

.profile__input-label {
	font-family: $font-family-roboto;
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	text-align: left;
	color: $main-black-light;
	// margin: 0 0 26px;
	margin: 0 0 7px;
	padding: 0;
}

.profile__input-label:nth-last-child(2) {
	margin-bottom: 0;
}

.profile__button-container {
	display: flex;
	margin-top: calc(42px - 32px - 4px);
	gap: 8px;
}

.profile__change-button {
	font-family: $font-family-roboto;
	width: fit-content;
	height: 36px;
	color: $main-white;
	background-color: $accent-blue;
	border: none;
	outline: none;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	text-align: center;
	padding: 8px 16px;
	margin-top: 42px;
	@include border-radius(8px);
	cursor: pointer;
}

.no-margin {
	margin: 0;
}

.profile__cancel-button {
	font-family: $font-family-roboto;
	width: fit-content;
	color: $main-gray-blue-dark;
	background-color: transparent;
	height: 36px;
	border: none;
	outline: none;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	text-align: center;
	padding: 8px 16px;
	cursor: pointer;
}

.profile__server-error {
	display: block;
	justify-content: center;
	align-items: center;
	min-height: 18px;
	max-height: 32px;
	color: #f92e2e;
	font-size: 13px;
	font-weight: 400;
	line-height: 14px;
	margin: 0;
	// margin: 20px 0 8px;
}

@media screen and (max-width: $breakpoint-sm) {
	.profile__personal-info-container {
		margin-bottom: 16px;
	}
	.profile__field-name {
		margin-bottom: 24px;
	}
	.profile__change-button {
		margin-top: 36px;
		height: 40px;
	}
	.form_place_profile {
		width: 100%;
	}
}

@import '../../scss/variables.scss';
.delete-profile-form__container {
	display: flex;
	max-width: 312px;
	justify-content: left;
	padding: 0;
}

.delete-profile-form__error {
	min-height: 22px;
	max-height: 32px;
	text-align: center;
	color: $modal-btn-red;
	font-size: 13px;
	font-weight: 400;
	line-height: 14px;
	padding: 6px 0;
}

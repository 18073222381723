@import '../../scss/variables.scss';
@import '../../scss/mixins.scss';

.default-button {
	@include flex-center;
	border: none;
	border-radius: 8px;
	column-gap: 8px;
	width: fit-content;

	&_big {
		@include button-text;
		padding: 12px 16px;
		height: 44px;
	}

	&_small {
		@include button-text-small-size;
		padding: 8px 16px;
		height: 36px;
	}

	&_primary {
		background: $accent-blue;
		color: $main-white;
		svg path {
			fill: $main-white; // задает цвет svg
		}

		&:hover,
		&:focus {
			background-color: $accent-blue-button-focused;
		}
	}
	&_secondary {
		background: $accent-light-blue;
		color: $accent-blue;

		svg path {
			fill: $accent-blue; // задает цвет svg
		}

		&:hover,
		&:focus {
			background-color: $accent-light-blue-button-focused;
		}
	}

	&_flat {
		color: $main-gray-blue-dark;
		background-color: inherit;
		svg path {
			fill: $main-gray-blue-dark; // задает цвет svg
		}

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
}

.btn-add-photo {
	@include flex-center;
	border: none;
	border-radius: 8px;
	column-gap: 16px;
	@include chat-username-text;
	padding: 12px 16px;
	height: 36px;
	background: rgba(252, 252, 252, 0.8);
	color: $accent-blue-button-focused;
	svg path {
		fill: $accent-blue-button-focused; // задает цвет svg
	}
}

.btn-add-photo-small {
	@include flex-center;
	background: rgba(252, 252, 252, 0.8);
	padding: 6px;
	height: 36px;
	width: 36px;
	border: none;
	border-radius: 50%;
	svg path {
		fill: $accent-blue-button-focused; // задает цвет svg
	}
}

// @media screen and (max-width: 495px) {
// 	.button-clouse-foto-file {
// 		left: 130px;
// 	}
// }

// @media screen and (max-width: 435px) {
// 	.button-clouse-foto-file {
// 		left: 110px;
// 	}
// }

// @media screen and (max-width: 375px) {
// 	.button-clouse-foto-file {
// 		left: 100px;
// 	}
// }

.checkbox-register__container {
	height: 44px;
	margin: 0;
	margin-top: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.checkbox__label {
	margin: 0;
	color: var(--Primary-Black-light, #515151);
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	display: flex;
	align-items: start;
	text-align: justify;
	cursor: pointer;
}

.checkbox__input {
	margin: 0;
	width: 16px;
	height: 16px;
	margin-right: 8px;
	fill: var(--Primary-White, #fcfcfc);
	stroke-width: 2px;
	stroke: var(--Primary-Gray-blue-light, #c9d1e5);
	cursor: pointer;
}

.checkbox__input:checked {
	background-color: #1478ff;
}

.checkbox__error-container {
	min-height: 19px;
	// width: 100%;
	// display: block;
	display: flex;
	align-self: start;
}

.input-checkbox-register__error {
	display: flex;
	align-items: self-start;
	padding-top: 5px;
	margin: 0;
	font-family: Roboto;
	font-size: 13px;
	font-weight: 400;
	line-height: 14px;
	text-align: left;
	color: #f92e2e;
	text-align: start;
}

@media screen and (max-width: 648px) {
	.input-checkbox-register__error {
		font-size: 11px;
	}
}

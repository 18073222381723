.not-found {
	background-color: #f2f3f7;
	/* min-height: 100vh; */
	display: flex;
	justify-content: center;
}

.not-found__container {
	margin: 0;
	padding-top: 100px;
	padding-bottom: 288px;
	width: 326px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.not-found__title {
	margin: 0;
	padding: 0;
	color: var(--Accent, #1478ff);
	text-align: center;
	font-family: Roboto Flex;
	font-weight: 900;
	font-size: 142px;
	font-style: normal;
	line-height: 148px; /* 104.225% */
}

.not-found__subtitle {
	margin: 0;
	padding: 0;
	color: #515151;
	text-align: center;
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px; /* 137.5% */
}

.not-found__link {
	color: var(--Accent, #1478ff);
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	text-decoration-line: underline;
	border: 0;
	background-color: transparent;
}

.not-found__image {
	padding-top: 64px;
}

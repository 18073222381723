.register-form__container {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 425px;
}
.register-form {
	margin: 0;
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.register-form__link-politiks {
	color: inherit;
}

.register-form__server-error {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 18px;
	max-height: 32px;
	color: #f92e2e;
	font-size: 13px;
	font-weight: 400;
	line-height: 14px;
	margin: 20px 0 8px;
}

.register-form__button-register {
	margin: 0;
	border-radius: 8px;
	background: var(--Accent-Blue, #1478ff);
	padding: 0;
	width: 100%;
	// width: 326px;
	height: 44px;
	color: var(--Primary-White, #fcfcfc);
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	text-align: center;
	align-self: center;
	cursor: pointer;
}

.register-form__paragraph {
	margin: 0;
	padding: 16px 0px;
	color: var(--Primary-Gray-blue-dark, #737a8e);
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	display: flex;
	gap: 4px;
	align-self: center;
}

@media screen and (max-width: 648px) {
	.register-form__container {
		width: calc(335px - (16px * 2));
	}
}

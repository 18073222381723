@import '../../scss/variables.scss';

@mixin border-radius($radius) {
	border-radius: $radius;
}

.profile {
	width: 100%;
	height: 100%;
	background-color: $main-background;
	margin-bottom: 100px;
}

.profile__container {
	display: flex;
	max-width: calc(415px + 593px + 32px);
	justify-content: center;
	padding: 40px 0 0;
	margin: 0 auto;
	column-gap: 32px;
}

.profile__navigation {
	width: 325px;
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.profile__avatar {
	position: relative;
	width: 120px;
	height: 120px;
	@include border-radius(50%);
	background-size: cover;
	background-position: center;
	margin-right: 20px;
}

.profile__info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 14px;
}

.profile__menu {
	display: flex;
	flex-direction: column;
	background-color: $main-white;
	padding: 16px;
	@include border-radius(12px);
	gap: 4px;
}

.profile__menu-link {
	font-family: $font-family-roboto;
	display: flex;
	width: 100%;
	height: 48px;
	padding: 0 0 0 24px;
	align-items: center;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	background-color: transparent;
	color: $main-gray-blue-dark;
	outline: none;
	border: none;
	@include border-radius(8px);
	cursor: pointer;
	text-decoration: none;
}

.profile__menu-link_active {
	background-color: $main-background;
}

.profile__menu-link_inactive {
	background-color: transparent;
}

.profile__menu:has(.profile__button-logout) {
	padding: 8px 16px;
}

.profile__menu-link-icon {
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	margin-right: 8px;
}

.profile__personal-info {
	display: flex;
	height: fit-content;
	gap: 12px;
	width: 683px;
	flex-direction: column;
	padding: 0;
}

.form__title_place_profile {
	font-family: $font-family-roboto-flex;
	color: $secondary-black;
	text-align: left;
	font-size: 20px;
	font-weight: 600;
	line-height: 22px;
	letter-spacing: 0.01em;
	margin: 0 0 16px;
}

.password-data > .form__title_place_profile {
	margin: 0 0 12px;
}

.profile__nav-button {
	width: 100%;
	font-family: $font-family-roboto;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	outline: none;
	border: none;
	text-decoration: none;
	text-align: left;
	background-color: transparent;
	color: $main-gray-blue-dark;
	cursor: pointer;
}

.profile__button-logout {
	height: 48px;
	background: url('../../images/logout-icon.svg') no-repeat 8.5% 50%;
	background-size: 24px;
	padding-left: 56px;
	@include border-radius(8px);
}

.profile__button-account-delete {
	height: 36px;
	display: flex;
	gap: 8px;
	align-items: center;
	padding: 0;
	background: url('../../images/profile-page-arrow-right-icon.svg') no-repeat
		100% 50%;
}

.button-y {
	border: none;
	@include border-radius(10px);
	width: 200px;
	height: 30px;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	color: $main-white;
	background-color: #aaa;
}

.popup__title {
	font-family: $font-family-roboto;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	text-align: left;
	color: $main-black-light;
	margin: 0;
	padding-top: 12px;
}

.popup__header.popup__header_left-aligned {
	text-align: left;
}

.popup__button-container {
	display: flex;
	max-width: 312px;
	gap: 12px;
	justify-content: left;
	padding: 28px 0 0;
}

.popup__button {
	font-family: $font-family-roboto;
	border: none;
	outline: none;
	padding: 0;
	margin: 0;
	text-align: center;
	height: 44px;
	cursor: pointer;
	@include border-radius(8px);
}

.popup__button.popup__button-n {
	background: none;
	color: $main-gray-blue-dark;
	width: fit-content;
	font-weight: 700;
	line-height: 18px;
	font-size: 16px;
}

.popup__button.popup__button-y {
	max-width: 146px;
	padding: 12px 42px;
	background: $modal-btn-red;
	color: $main-white;
	text-align: center;
	font-weight: 700;
	line-height: 20px;
	font-size: 16px;
}

@media screen and (max-width: $breakpoint-md) {
	.profile__container {
		width: 90%;
	}
	.profile__personal-info {
		width: 43.52em;
	}
}

@media screen and (max-width: $breakpoint-sm) {
	.profile {
		background-color: $main-white;
	}
	.profile__container {
		display: grid;
		grid-template-columns: 100%;
		grid-template-areas:
			'menu'
			'menu-content';
		padding: 0;
	}
	.profile__navigation {
		grid-area: menu;
		flex-direction: row;
		gap: 0;
		width: 100%;
		background-color: transparent;
		padding: 10px;
		border-bottom: 1px solid #e8eaf1;
	}
	.profile__menu {
		flex-direction: row;
		background-color: transparent;
		padding: 0;
		gap: 0;
	}
	.profile__button-account-delete {
		display: none;
	}
	.profile__menu-link {
		height: 32px;
		padding: 6px 12px;
	}
	.profile__menu-link_active {
		height: 32px;
		background-color: transparent;
		border: 1px solid #c9d1e5;
		font-size: 14px;
		font-weight: 700;
		line-height: 20px;
		color: $accent-blue;
		text-align: center;
	}
	.profile__menu-link_inactive {
		background-color: transparent;
		color: $main-gray-blue-dark;
		font-size: 14px;
		font-weight: 700;
		line-height: 20px;
	}
	.profile__menu-link-icon {
		display: none;
	}
	.profile__nav-button {
		background-color: transparent;
	}
	.profile__button-logout {
		background: url(../../images/logout-icon-mobile.svg) no-repeat left;
		padding-left: 17px;
		height: 32px;
		color: $main-gray-blue-dark;
		font-size: 14px;
		font-weight: 700;
		line-height: 20px;
		margin-left: 15px;
	}
	.form__title_place_profile {
		display: none;
	}
	.profile__personal-info {
		padding: 20px 0;
		width: 100%;
	}
}

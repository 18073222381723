.input__container {
	display: flex;
	flex-direction: column;
	padding: 4px 0;
	text-transform: none;
}

.input__label {
	color: var(--Primary-Black-light, #515151);
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	text-transform: none;
}

.input__error-container {
	margin-top: 5px;
	min-height: 19px;
	max-height: 32px;
	display: block;
}

.input__error {
	margin: 0;
	font-family: Roboto;
	font-size: 13px;
	font-weight: 400;
	line-height: 14px;
	text-align: left;
	color: #f92e2e;
}

.input {
	margin: 0;
	margin-top: 4px;
	padding: 12px 16px;
	display: flex;
	width: 100%;
	// width: 425px;
	height: 44px;
	border: 1px solid #c9d1e5;
	align-items: flex-start;
	border-radius: var(--xs, 8px);
	text-transform: none;
}

.input:focus {
	outline: 1px solid var(--Accent, #1478ff);
	text-transform: none;
}

.input-error {
	outline: 1px solid #f92e2e;
}

@media screen and (max-width: 648px) {
	.input__container {
		width: 100%;
	}

	.input__error-container {
		min-height: 20px;
		margin: 0;
	}
}
